/* work-around to get full width on date range picker library */
.rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
}

.analytics.multiSelectDropdown {
    color: #000
}

.custom-card-wrapper{
    max-width: 100% !important;
}

.custom-card-container{
    height: 100%;
}

.custom-card-container > .p-card-body > .p-card-content {
    padding: 0;
}

