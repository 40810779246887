/*!
 * variables.scss (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file variables.scss
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

// Your custom theme/variable overrides go here
// The XXL and BG are custom breakpoints to handle data tables with more number of headers. e.g. Products data table.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1670px,
  first-custom-admin-subscription-page-breakpoint: 1480px,
  second-custom-admin-subscription-page-breakpoint: 1052px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1350px,
  3xl: 1640px,
  first-custom-admin-subscription-page-breakpoint: 1440px,
  second-custom-admin-subscription-page-breakpoint: 1000px
);

// Body
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';