/*!
 * _prime-components.scss (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file _prime-components.scss
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

 $highlightBg: $primaryColor;

 .p-datatable .p-datatable-header {
  background-color: $black;
 }

.p-dialog {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.p-button {
  cursor: pointer;
  background-color: $primaryColor;
  font-size: 16px;

  &.btn-outline-primary {
    font-size: 24px;
  }

  &.p-button-raised {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .pi {
    font-size: 24px;
  }
}

.p-inputtext,
.p-dropdown {
  width: 100%;
}

.p-toggleable-content-enter-active {
  transition: none;
}

.p-toggleable-content-exit-active {
  transition: none;
}

.p-datatable {
  .p-datatable-tbody {
    .p-rowgroup-header {
      color: $pink;
      font-size: 20px;
    }
    & > tr > td {
      text-align: center;
    }
  }
}

.p-fieldset {
  .p-toggleable-content {
    height: 100%;
    .p-fieldset-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.p-card {
  display: flex;
  flex-direction: column;
  .p-card-header {
    img {
      height: 300px;
    }
  }
  .p-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    .p-card-footer {
      margin-top: auto;
    }
  }
}

.p-message {
  width: 100%;
}

.p-col-number {
  width: 110px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-category {
  width: 110px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-price {
  width: 90px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date {
  width: 140px;
  white-space: nowrap;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-updated {
  width: 120px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-no-time {
  width: 100px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-bool {
  width: 75px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-image {
  width: 120px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive {
  width: 140px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive-lg {
  width: 300px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-customer {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  @include media-breakpoint-down(md) {
    width: unset;
    max-width: unset;
    min-width: unset;
  }
}

// SIDE NAV
.p-sidebar {
  background: $black;
  color: white;
  border: 0px;
  z-index: 999;
}
@include media-breakpoint-up(sm) {
  .p-sidebar {
    position: unset;
  }
  .p-sidebar-left {
    width: auto;
  }
}

//SLIDE MENU
.p-slidemenu {
  width: 100%;
}

.p-slidemenu .active {
  background: #eaeaea;
}

.p-tieredmenu .p-menuitem-active > .p-submenu-list {
  z-index: 10;
}

//TABLE
.p-datatable {
  word-wrap: break-word;
}
.p-col-descriptive-lg {
  width: 300px;
  @include media-breakpoint-down(md) {
    width: unset;
  }
}

//DIALOG
.p-dialog .p-dialog-header {
  background: $black;
  border-bottom: 2px solid var(--accent-color);
}

//FIELDSET
.p-fieldset .p-fieldset-legend {
  color: unset;
  background: unset;
  font-weight: normal;
  border: unset;
}
legend {
  width: auto;
}

