.table-quantity-col button {
  border-radius: 25px;
}

body .light-section .p-button.float-right {
  padding: 10px 25px;
  background: var(--primary-control-color);
  color: #fff;
  box-shadow: none;
}

body .light-section .p-button.float-right:hover {
  padding: 10px 25px;
  border: 1px solid var(--primary-control-color);
  color: var(--primary-control-color);
  background: none;
  box-shadow: none;
}
body .light-section .p-button.float-right:active {
  color: #fff;
}

.disabled-message {
  margin-bottom: 10px;
}
.disabled-message .p-inline-message-text {
  font-size: 16px;
}
.container .warning-message-container {
  padding: 20px !important;
  border-radius: var(--primary-border-radius);
  width: 100%;
}
.container .warning-message-container {
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.warning-message span {
  color: #856404;
}
.warning-text-container {
  font-size: 18px;
  margin: 0 0 0;
}
#shopcontent .p-disabled {
  opacity: 2;
  background: rgba(0, 0, 0, 0.1);
}
.disabled-payment {
  pointer-events: none;
}