/*!
 * index.scss (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file index.scss
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/* GENERAL */

$pink: #00FFBD;
$black: #303030;
$brown: #252525;
$lightbrown: #585858;
$darkblack: #191919;
$lightblack: #323232;
$lightpink: #f1b6c8;


:root {
  --accent-color: #00FFBD;
  --hover-accent-color: #00b183;
  --selection-color-highlighted: #ffffff;
  --secondary-color: #303030;
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
  Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  --monospace-font-family: "Courier New",
Courier,
monospace;
  --text-color: #333333;
  --primary-text-color: #ffffff;
  --panel-content-border: 1px solid #c8c8c8;
  --subtable-text-color: #d3d0d0;
  --subtable-column-color: #d3cece;
  --subtable-border-color: #726f6f;
  --subtable-background: #706e6e;
  --table-row-hover-color: #e0f3ff;
}
html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  text-align: center;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 18px;
}

body {
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--font-family);
  color: #2f2f2f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  display: flex;

  #root {
    width: 100%;
  }

  .description {
    margin-top: ($spacer * 2);
    margin-bottom: ($spacer * 2);
    color: inherit;
    font-size: 21px;
    letter-spacing: 0.011em;
    line-height: 1.38;
    text-align: center;
  }

  .form-control:focus {
    border-color: var(--primary-control-color) !important;
    box-shadow: 0 0 0.2em var(--primary-control-color) !important;
  }

  .p-checkbox-label {
    font-weight: 400 !important;
    display: inline;
    font-size: 14px;
  }

  .p-radiobutton-label, .p-checkbox-label {
    margin-left: 0.5em;
  }

  .p-radiobutton, .p-checkbox {
    vertical-align: middle !important;
  }

  .p-checkbox-label-lg {
    @include media-breakpoint-down(sm) {
      margin-left: 1em;
    }
  }

  .p-checkbox-label-lg>.product-option-badge {
    @include media-breakpoint-down(sm) {
      margin-left: 0px !important;
    }
  }

  @keyframes show-pink {
    0% {
      color: #ffffff;
    }

    100% {
      color: var(--primary-control-color);
    }
  }

  /* ALERT */

  #alert {
    position: fixed;
    margin-top: -100px;
    z-index: 2000;
  }

  .show-alert {
    animation-name: add-alert;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  .sticky-alert {
    position: -webkit-sticky !important;
    /*Incase, For safari*/
    position: fixed !important;
  }

  .width-30 {
    width: 30%;
    float: left;
  }

  .width-70 {
    width: 70%;
    float: left;
  }

  .vh-50 {
    height: 50vh;
  }

  .vh-min-50 {
    min-height: 50vh;
  }

  .vh-25 {
    height: 25vh;
  }

  .expandable {
    resize: both;
    overflow: auto;
  }

  .date-picker-container {
    z-index: 1000;
    position: absolute;
    right: 15px;
    top: 35px;
    width: 570px;

    .rdrDefinedRangesWrapper{
      width: 400px;
    }
  }

  @keyframes add-alert {
    0% {
      margin-top: -100px;
    }

    100% {
      margin-top: 50px;
    }
  }

  .hide-alert {
    animation-name: remove-alert;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  @keyframes remove-alert {
    0% {
      margin-top: 50px;
    }

    100% {
      margin-top: -100px;
    }
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

  /* NAVBAR */

  nav {
    border-top: 3px solid var(--accent-color) !important;
  }

  /* FOOTER */

  #footer {
    z-index: 35;
  }

  footer {
    text-align: right;
  }

  footer .list-group-item {
    background-color: transparent;
    padding: 5px;
    border: none;
  }

  footer .list-group-item a {
    text-decoration: none;
    color: #ffffff;
  }

  footer .list-group-item a:hover {
    animation-name: show-pink;
    /* animation-duration: 300ms; //NOTE: property disabled due AW-2349 */
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  footer p {
    color: #919191;
  }

  /*APEX CHARTS*/
  .apexcharts-menu {
    background: white !important;
  }

  .apexcharts-menu-item:hover {
    background: #eaeaea !important;
  }

  .apexcharts-menu-icon svg {
    fill: #323232 !important;
  }

  /* FORM */

  .conditionsLink {
    text-decoration: none;
    cursor: pointer;
  }

  .condition>a {
    text-decoration: none;
    cursor: pointer;
  }

  /* CodeMirror */
  .CodeMirror {
    height: 100vh;
  }

  .max-height {
    height: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  /* CONSOLE */

  input[type='file']:hover,
  input[type='file']::-webkit-file-upload-button:hover {
    cursor: pointer;
    color: #000000;
  }

  .menuCol{
    min-width: 250px;
    max-width: 250px;
  }

  [class*='col-'] {
    padding-bottom: 15px;
  }

  #view {
    color: white;
    overflow: hidden;

    .row {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .plus-minus-box {
    .p-inputtext {
      width: auto;
    }
  }

  .form-group {
    margin-bottom: 0;

    .valid-feedback {
      display: block !important;
      visibility: hidden;
    }

    .invalid-feedback {
      display: block !important;
      visibility: visible;
    }

    .is-valid+.valid-feedback {
      display: block !important;
      visibility: visible;
    }

    .is-invalid+.valid-feedback {
      visibility: visible;
    }

    .was-validated :invalid~.invalid-feedback,
    .was-validated :invalid~.invalid-tooltip,
    .is-invalid~.invalid-feedback,
    .is-invalid~.invalid-tooltip {
      display: block !important;
      visibility: visible;
    }
  }

  .badge {
    font-size: 12px;
  }

  .product-option-badge {
    background-color: #6c757d;
    border-radius: 4px;
  }

  .log-field {
    font-size: 12px;
    white-space: normal;
  }

  .admin-image-badge {
    font-size: 14px;
    max-width: 75px;
  }

  .billing-description-col,
  .billing-payment-description-col {
    max-width: 110px;
    width: 110px;
    text-align: right;
    padding-right: 0px;
    padding-left: 0px;
  }

  .billing-address2-description-col {
    width: 110px;
  }
  .billing-company-col {
    width: 50%;
  }
  .billing-payment-description-col {
    max-width: 80px;
    width: unset;
  }

  .admin-customer-table {
    color: var(--text-color);
    text-align: left;
  }

  .admin-customer-table .billing-details {
    word-break: break-all;
  }

  .admin-customer-table tr:first-child td:first-child {
    border-top: none;
  }

  .skeleton {
    background: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  }

  #checkout_content {
    height: unset;
  }

  #checkout-summary_content {
    height: unset;
  }

  .table-product-row-label {
    display: none;
  }

  .table {
    color: inherit;
  }

  .table>tbody>tr>td,
  .table>thead>tr>td {
    padding: 1.20rem;
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .select-auto {
    -webkit-touch-callout: auto;
    /* iOS Safari */
    -webkit-user-select: auto;
    /* Safari */
    -khtml-user-select: auto;
    /* Konqueror HTML */
    -moz-user-select: auto;
    /* Old versions of Firefox */
    -ms-user-select: auto;
    /* Internet Explorer/Edge */
    user-select: auto;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  @include media-breakpoint-down(md) {
    .table-product-head {
      display: none;
    }

    .table-product-row,
    .table-options-row {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .table-product-row-empty {
      display: none !important;
    }

    .table-product-row-label {
      font-family: "Open Sans", "Helvetica Neue", sans-serif;
      display: block;
      text-align: left;
    }

    .table-product-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-option-quantity-col,
    .table-option-per-item-price-col {
      display: none;
    }

    .transaction-success-actions {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 85px;
    }
  }

  .text-monospace {
    font-family: var(--monospace-font-family);
  }

  .react-pdf__Document {
    margin: 0 auto;
    margin-top: 2em;
  }

  #termsDialog>.p-dialog-titlebar {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
  }

  #termsDialog>div.p-dialog-titlebar>div>button {
    color: #919191;
  }

  #termsDialog>div.p-dialog-titlebar>div>button:hover {
    color: #ffffff;
  }

  .collapse-button {
    position: absolute;
    right: -1em;
    top: 6em;
    font-size: 12px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #button-discount {
    height: 34px;

    @include media-breakpoint-down(sm) {
      margin-top: 1em;
    }
  }

  #button-discount>span.p-button-text.p-c {
    padding: 0px;
  }

  .product-image-placeholder {
    min-height: 300px;
    width: 100%;
    transition: 0.5s;
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }

  #termsDialog>div.p-dialog-content {
    overflow: hidden;
    padding-top: 0px;
    border: none;
  }

  .terms-and-conditions-iframe {
    margin-bottom: 1.5em;
    height: -webkit-fill-available;
    height: 75vh;
    border: none;
  }

  /* HAMBURGER */
  .hamburger {
    // position: absolute;
    left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    margin-right: 15px;
    margin-left: 15px;

    @include media-breakpoint-down(sm) {
      width: 1rem;
    }

    span {
      width: 2rem;
      height: 0.25rem;
      background: #e8e8e8;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: rotate(45deg);
        /*: 'rotate(0)'}; */
      }

      :nth-child(2) {
        opacity: '0';
        /* : '1'}; */
        transform: translateX(20px);
        /* : 'translateX(0)'}; */
      }

      :nth-child(3) {
        transform: rotate(-45deg)
          /* : 'rotate(0)'}; */
      }
    }
  }

  .topNav {
    background-color: #303030;
    display: none;
  }

  @media screen and (max-width: 768px) {
    .topNav {
      display: -webkit-box;
    }
  }

  @media screen and (max-width: 500px) {
    .date-picker-container {
      .rdrDefinedRangesWrapper{
        display: none;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 1200px) {
    .date-picker-container {
      width: 500px;
    }
  }

  @media screen and (max-width:768px) {
    .header-company-name {
      display: none;
    }

    .layout-mask {
      z-index: 998;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 8%;
      margin-top: 2px;
      left: 0;
      opacity: .7;
      background-color: #383838;
      -webkit-animation: layoutMaskFade .3s;
      animation: layoutMaskFade .3s;
    }

    #view {
      margin-top: 12%;
    }
    
    .p-dialog {
      margin-top: 12%;
    }
  }

  .edit-note-button {
    top: 0.50em;
    right: 20px;
    position: absolute;
  }

  .not-found-page {
    min-height: 70vh;
  }

  .not-found-page-message {
    margin-top: 5em;
  }

  .rdrInputRange span {
    color: black;
  }

  .fa {
    margin-left: 0.5em;
  }

  .truncate>div>span>span:nth-child(1)>span:nth-child(5)>span>span:nth-child(2)>a {
    color: #ffffff;
    text-decoration: underline;
  }

  .truncate>div>span:nth-child(2)>a {
    color: #ffffff;
    text-decoration: underline;
  }

  .shop-body {
    min-height: calc(100vh - 315px);
  }

  .insta-image-favicon-container {
    position: relative;
    width: -webkit-fit-content;
  }

  .remove-image {
    display: inline;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 10em;
    padding: 2px 6px 3px;
    text-decoration: none;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }

  .remove-image:hover {
    background: #E54E4E;
    color: white;
    padding: 3px 7px 5px;
    top: -11px;
    right: -11px;
  }

  .remove-image:active {
    background: #E54E4E;
    color: white;
    top: -10px;
    right: -11px;
  }

  .percentage-calculation,
  .amount-calculation {
    font-size: 0.625em;
  }

  .p-toggleable-content-dropdown .p-toggleable-content {
    margin-left: 10%;
  }

  @include media-breakpoint-up(lg) {
    .custom-transaction-dialog-container {
      width: 80% !important;
    }
  }

  .custom-transaction-dialog-container .p-dialog-content {
    min-height: 300px;
  }

  legend {
    font-size: 24px !important;
    border-bottom: 0px;
    margin: 0px;
  }

  /* cross browser fix for the payment data input */
  #payment-data {
    flex: 1;
  }

  .table-product-col-description {
    width: 230px;
    max-width: 230px;
  }

  .table-product-col-options-description,
  .table-product-col-description {
    padding: 0.75rem !important;
  }

  @media (max-width: 991px) {
    .table-product-col-options-description,
    .table-product-col-description {
      padding: 1.2rem !important;
    }
  }

  .table-product-col-description {
    @include media-breakpoint-down(md) {
      max-width: unset;
      width: unset;
      display: flex !important;
    }
  }

  .table-product-options-description {
    @include media-breakpoint-down(sm) {
      max-width: 240px;
    }
  }

  .p-sortable-column-badge {
    text-align: center !important;
  }

  .product-image {
    width: 100%;
    min-height: '300px';
    border-radius: var(--primary-border-radius);
  }

  /* print view */
  @media print {
    header {
      display: none !important;
    }

    header::after {
      display: none !important;
    }

    .header-bg::after {
      display: none !important;
    }

    header::before {
      display: none !important;
    }

    footer {
      display: none !important;
    }

    footer::after {
      display: none !important;
    }

    footer::before {
      display: none !important;
    }

    .shop-body {
      padding-top: 0px;
    }

    &::after {
      display: none !important;
    }
  }

  .icon-button {
    font-size: 20px;
  }

  .log-date-picker .date-picker-container{
    top: 55px;
  } /**< For Date picker in Log.*/

  .email-confirm {
    padding-top: 2.4rem;
  }

  @import '_prime-components';
  @import '_button';
}