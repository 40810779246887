/*!
 * _prime-components.scss (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file _prime-components.scss
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
.p-component {
  font-family: var(--font-family);
  font-size: 16px;
}

.p-panel-header {
  border-color: $black !important;
  background-color: $black !important;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

.p-dialog {
  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(sm) {
    width: 75%;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 65px;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.p-button {
  cursor: pointer;
  background-color: var(--primary-control-color);
  border-color: var(--primary-control-color);
  font-size: 16px;
  z-index: 99;

  &.btn-outline-primary {
    font-size: 24px;
  }

  &.p-button-raised {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .pi {
    font-size: 18px;
  }

  .bi {
    font-size: 16px;
  }
}

.p-button-icon-only .p-button-label {
  display: none;
}

@media screen and (max-width: 40em) {
  .placeholder-table {
    display: block;
    overflow-x: auto;
  }
}

.p-dropdown:not(.p-disabled).p-focus,
.p-dropdown:focus-visible {
  outline: 0 none;
  outline-offset: 0;
}

.p-inputtext,
.p-dropdown {
  width: 100%;
}

.p-inputtext:disabled {
  opacity: 0.5;
}

.p-dropdown-label {
  color: $darkblack;
}

.p-toggleable-content-enter-active {
  transition: none;
}

.p-toggleable-content-exit-active {
  transition: none;
}

.p-toggleable-content-dropdown {
  #undefined_content {
    height: auto;
  }
}

.p-panel {
  .p-panel-titlebar {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    border-top-right-radius: 1px;
  }

  &.compact .p-panel-content {
    padding: 0 0 0 10px;
  }
}

.p-panelmenu {
  .p-toggleable-content-dropdown .p-toggleable-content {
    height: 90px;
  }

  .p-panelmenu-content {
    border: none;

    .p-menuitem-active {
      .p-menuitem-link {
        border: red;
      }
    }

    .p-menuitem {
      color: white;

      .p-menuitem-link {
        color: white;

        &:focus {
          box-shadow: inset 0 0 0 0.2em var(--primary-control-color);
        }

        &:not(.p-disabled):hover {
          background: #4c4c4c;
        }
      }
    }
  }
}

.p-fieldset {
  .p-toggleable-content {
    height: 100%;

    .p-fieldset-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.p-card {
  display: flex;
  flex-direction: column;

  .p-card-header {
    img {
      height: 300px;
      object-fit: contain;
    }
  }

  .p-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-card-footer {
      margin-top: auto;
    }
  }
}

.p-message {
  width: 100%;
}


.p-col-number {
  width: 110px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-category {
  width: 110px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-price {
  width: 150px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date {
  width: 140px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-updated {
  width: 120px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-no-time {
  width: 150px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-bool {
  width: 75px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-expander {
  width: 50px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-image {
  width: 120px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive {
  width: 140px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive-lg {
  width: 300px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-customer {
  width: 200px;
  max-width: 200px;
  min-width: 200px;

  @include media-breakpoint-down(md) {
    width: unset;
    max-width: unset;
    min-width: unset;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary-control-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  transform: none;
  animation: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  background: var(--primary-control-color);
}

.p-panelmenu-header.p-highlight {
  background: transparent;
}