.custom-quantity-button-container {
  min-width: 42px !important;
  width: 42px !important;
  height: 42px;
}

.custom-labels-product-column {
  font-size: 12px;
}

.custom-padding-product-column {
  padding-left: 12px !important;
}

.custom-margin-quantity-column {
  margin-top: -6px;
}

@media screen and (max-width: 991px) {
  .sla-information {
    display: none;
  }
  .custom-padding-product-column {
    padding-left: 1.2rem !important;
  }
}

.product-option-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  min-width: 65px;
}