.custom-margin-between-section {
  margin-bottom: 15px !important;
}

#terms_and_condition_url {
  color: var(--primary-control-color) !important;
}

.email-confirmation,
.template-html * {
  font-family: var(--font-family);
}

.container .calltoaction-container {
  padding: 40px !important;
  border-radius: var(--primary-border-radius);
  width: 100%;
}

#calltoaction-button {
  width: auto;
  margin-top: 15px;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 20px;
  white-space: normal;
}
#calltoaction-button:hover {
  background-color: #fff !important;
  color: #04a9c7 !important;
  border: 2px solid #fff !important;
}
#calltoaction-button:focus {
  background-color: #fff !important;
  color: #04a9c7 !important;
  border: 2px solid #fff !important;
  box-shadow: none !important;
  outline: none !important;
}
/* start of style for 575px breakpoint */
@media screen and (max-width: 575px) {
  #calltoaction-button {
    width: 100%;
  }
}

.custom-ancor-container {
  border-radius: 25px !important;
}

.calltoaction-second-row-container {
  margin-top: 10px
}