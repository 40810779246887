.braintree-sheet__container {
  margin: 0;
}

#shopcontent .braintree-large-button {
  background-color: transparent;
}

.braintree-large-button span {
  border-bottom: 0;
  padding-bottom: 0;
}

.braintree-large-button:hover {
  font-weight: 400;
}

#shopcontent .braintree-large-button:hover {
  background-color: transparent;
  color: var(--primary-control-color);
}

.braintree-option:first-child {
  border-radius: var(--primary-border-top-radius);
}

.braintree-option:last-child {
  border-radius: var(--primary-border-bottom-radius);
}

.braintree-show-card .braintree-card,
.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field,
.braintree-show-paypal .braintree-paypal {
  border-radius: var(--primary-border-radius);
}

.custom-choose-another-way-to-pay-button-style,
.braintree-loader__container,
.braintree-method {
  border-radius: var(--primary-border-radius) !important;
}

.braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error .braintree-form__field .braintree-form__hosted-field,
.braintree-sheet--has-error .braintree-sheet {
  border-width: 3px;
}

.braintree-form__icon-container,
.braintree-sheet--has-error .braintree-sheet__error .braintree-sheet__error-icon {
  display: none;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error,
.braintree-sheet--has-error .braintree-sheet,
.braintree-sheet--has-error .braintree-sheet__error .braintree-sheet__error-text {
  color: #a80000;
}

.braintree-form__field {
  width: 318px;
}

.braintree-form__field > div > input, 
.braintree-form__field > div > input:focus, 
.braintree-form__field > div > input:hover {
  padding: 0;
}

.braintree-form__flexible-fields {
  display: block !important;
}