/*!
 * Shop.scss (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Shop.scss
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

$body-bg: #fff;
$body-color: #000000;

@import 'bootstrap';

@import 'node_modules/primereact/resources/themes/nova-alt/theme';
@import 'node_modules/primereact/resources/primereact';
@import 'node_modules/primeicons/primeicons';

@import "_common";
