.loginWidget {
  border-radius: 10px;
  background-color: #2f2f2f;
  padding: 20px;
  color: white;
  padding-bottom: 0px;
}
.card-row {
  margin-bottom: 20px;
  font-weight: bold;
}
.application-title {
  color: white;
  font-weight: 600;
  font-size: 30px !important;
}
.loggedIn-card .example-warper .profile-card, 
.loggedIn-card .example-warper .profile-card i, 
.loggedIn-card .example-warper .profile-card .card-title {
  background-color: #2f2f2f !important;
  color: white;
  border: none !important;
}
.loginWidget-row {
  margin-top: -10px;
  padding: 0px;
}
.loginWidget-icon {
  margin-right: 0.5em !important;
  margin-left: 0px !important;
}
.loggedIn-card .example-warper .profile-card .card-text {
  padding-top: 10px;
}
.loggedIn-card .example-warper .profile-card .email-link, 
.loggedIn-card .example-warper .profile-card .username,
.loggedIn-card .example-warper .profile-card .card-title {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
}
.loggedIn-card .example-warper .profile-card .card-body {
  padding-left: 0px;
}