.summary-checkbox-container {
  min-height: 33px;
  clear: both;
}

#agreement-text a {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-control-color);
  background-color: transparent !important;
}

#agreement-text a:hover {
  color: var(--primary-control-hover-color);
}

.customMessageToast .p-inline-message-icon,
.customMessageToast .p-inline-message-text{
  font-size: 16px;
}

.customMessageToast {
  border: solid #ff5757 !important;
  border-width: 0px 0px 0px 6px !important;
}