.custom-dropdrown-container-shop-page {
  height: 37.72px;
}

.custom-dropdrown-container-shop-page span:first-of-type {
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 9px;
}

.custom-billing-address-container {
  margin-top: 15px;
}

#shopcontent .p-dropdown .p-dropdown-trigger {
  width: 37.7px;
}

#shopcontent .p-dropdown .p-dropdown-clear-icon { 
  right: 3.5rem;
  font-size: 1.6rem;
  margin-top: -0.8rem;
}
