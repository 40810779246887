/*!
 * index.scss (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file index.scss
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/* GENERAL */

$pink: var(--primary-control-color);
$black: #303030;

$text-color: #000 !default;
$primaryColor: var(--primary-control-color) !default;
$secondaryColor: $black !default;
$tableHeaderBg: $pink !default;
$highlightBg: $primaryColor;
$font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
  Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;

$monospace-font-family: "Courier New",
Courier,
monospace;

:root {
  --primaryColor: var(--primary-control-color);
  --primaryTextColor: #000;
  --panelContentBorder: 1px solid $black;
  --highlightBg: $primaryColor;
}

html {
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  text-shadow: none;
  text-align: left;
  color: $text-color
}

h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 18px;
}

body {
  line-height: 1.5;
  font-weight: 400;
  font-family: $font-family;
  color: #2f2f2f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  #root {
    width: 100%;
  }

  ::selection {
    background-color: var(--primary-control-color) !important;
    color: $text-color;
  }

  .light-section {
    background-color: #fff !important;
    flex-grow: 1;
   }
  
   .p-checkbox .p-checkbox-box.p-highlight {
     background-color: var(--primary-control-color);
     border: 1px solid var(--primary-control-color);
   }

   .light-section button, 
   .light-section .p-button, 
    .p-checkbox-box {
      background: none;
      border: 1px solid var(--primary-control-color);
      color: var(--primary-control-color);
      box-shadow: none;
      outline: none;
   }

  .light-section button:not(.p-button-icon-only) {
    width: 100%;
  }

   .light-section button:hover, 
   .light-section .p-button:hover,
   .light-section button:active,
   .light-section .p-button:active
   {
    background: var(--primary-control-hover-color) !important;
    color: #fff !important;
    border: 1px solid var(--primary-control-hover-color) !important;
  }
  .light-section button:active,
  .light-section .p-button:active {
    box-shadow: none;
    outline: none;
  }
  .light-section button:focus {
    outline: 0 !important;
    box-shadow: none;
    background: none !important;
    color: var(--primary-control-hover-color) !important;
    border: 1px solid var(--primary-control-hover-color) !important;
  }
  .light-section .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .description {
    margin-top: ($spacer * 2);
    margin-bottom: ($spacer * 2);
    color: inherit;
    font-size: 21px;
    letter-spacing: 0.011em;
    line-height: 1.38;
    text-align: center;
  }

  .form-control:focus {
    border-color: $primaryColor !important;
    box-shadow: 0 0 0.2em $primaryColor !important;
  }

  .form-label,
  .p-checkbox-label {
    font-weight: 400 !important;
  }

  .p-checkbox-label-lg {
    @include media-breakpoint-down(sm) {
      margin-left: 1em;
    }
  }

  .p-checkbox-label-lg>.product-option-badge {
    @include media-breakpoint-down(sm) {
      margin-left: 0px !important;
    }
  }

  @keyframes show-pink {
    0% {
      color: #ffffff;
    }

    100% {
      color: $primaryColor;
    }
  }

  /* ALERT */

  #alert {
    position: absolute;
    margin-top: -100px;
    z-index: 2000;
  }

  .show-alert {
    animation-name: add-alert;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  .sticky-alert {
    position: -webkit-sticky !important;
    /*Incase, For safari*/
    position: fixed !important;
  }

  .width-30 {
    width: 30%;
    float: left;
  }

  .width-70 {
    width: 70%;
    float: left;
  }

  .vh-50 {
    height: 50vh;
  }

  .vh-min-50 {
    min-height: 50vh;
  }

  .vh-25 {
    height: 25vh;
  }

  .expandable {
    resize: both;
    overflow: auto;
  }

  .date-picker-container {
    z-index: 12;
    position: absolute;
    left: 15px;
    top: 50px;
  }

  @keyframes add-alert {
    0% {
      margin-top: -100px;
    }

    100% {
      margin-top: 50px;
    }
  }

  .hide-alert {
    animation-name: remove-alert;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  @keyframes remove-alert {
    0% {
      margin-top: 50px;
    }

    100% {
      margin-top: -100px;
    }
  }

  /* NAVBAR */

  nav {
    border-top: 3px solid $primaryColor !important;
  }

  /* FORM */

  .conditionsLink {
    color: $primaryColor;
    text-decoration: none;
    cursor: pointer;
  }

  .condition>a {
    color: $primaryColor;
    text-decoration: none;
    cursor: pointer;
  }

  /* CONSOLE */

  input[type='file']:hover,
  input[type='file']::-webkit-file-upload-button:hover {
    cursor: pointer;
    color: #000000;
  }

  [class*='col-'] {
    padding-bottom: 15px;
  }

  #view {
    color: white;
    overflow: inherit;

    .row {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .plus-minus-box {
    .p-inputtext {
      width: auto;
    }
  }

  .form-group {
    margin-bottom: 0;

    .valid-feedback {
      display: block !important;
      visibility: hidden;
    }

    .invalid-feedback {
      display: block !important;
      visibility: visible;
    }

    .is-valid+.valid-feedback {
      display: block !important;
      visibility: visible;
    }

    .is-invalid+.valid-feedback {
      visibility: visible;
    }

    .was-validated :invalid~.invalid-feedback,
    .was-validated :invalid~.invalid-tooltip,
    .is-invalid~.invalid-feedback,
    .is-invalid~.invalid-tooltip {
      display: block !important;
      visibility: visible;
    }
  }

  .badge {
    font-size: 16px;
  }

  .product-option-badge {
    background-color: #6c757d;
    border-radius: 4px;
  }

  .log-field {
    font-size: 12px;
    white-space: normal;
  }

  .admin-image-badge {
    font-size: 14px;
    max-width: 75px;
  }

  .billing-description-col,
  .billing-payment-description-col {
    max-width: 70px;
    width: 70px;
    text-align: right;
    padding-right: 0px;
    padding-left: 0px;
  }

  .billing-address2-description-col {
    width: 110px;
  }

  .billing-payment-description-col {
    max-width: 80px;
    width: unset;
  }

  .admin-customer-table {
    color: $text-color;
    text-align: left;
  }

  .admin-customer-table tr:first-child td:first-child {
    border-top: none;
  }

  .skeleton {
    background: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  }

  #checkout_content {
    height: unset;
  }

  #checkout-summary_content {
    height: unset;
  }

  .table-product-row-label {
    display: none;
  }

  .table {
    color: inherit;
  }

  .table-quantity-col {
    display: flex;
    justify-content: center;
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .select-auto {
    -webkit-touch-callout: auto;
    /* iOS Safari */
    -webkit-user-select: auto;
    /* Safari */
    -khtml-user-select: auto;
    /* Konqueror HTML */
    -moz-user-select: auto;
    /* Old versions of Firefox */
    -ms-user-select: auto;
    /* Internet Explorer/Edge */
    user-select: auto;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .table-quantity-col>input {
    text-align-last: center;
    width: 70px;
  }

  @include media-breakpoint-down(md) {
    .table-product-head {
      display: none;
    }

    .table-product-row,
    .table-options-row {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .table-product-row-empty {
      display: none !important;
    }

    .table-product-row-label {
      font-family: "Open Sans", "Helvetica Neue", sans-serif;
      display: block;
      text-align: left;
    }

    .table-product-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-option-quantity-col,
    .table-option-per-item-price-col {
      display: none;
    }

    .table-quantity-col {
      justify-content: flex-end;
    }

    .table-quantity-col>.btn-outline-secondary:nth-child(3) {
      margin-right: 0px !important;
    }

    .transaction-success-actions {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 85px;
    }
  }

  .text-monospace {
    font-family: $monospace-font-family;
  }

  .edit-note-button {
    top: 0.65em;
    right: 28px;
    position: absolute;
  }

  .not-found-page {
    min-height: 70vh;
  }

  .not-found-page-message {
    margin-top: 5em;
  }

  .rdrInputRange span {
    color: black;
  }

  .fa {
    margin-left: 0.5em;
  }

  .truncate>div>span>span:nth-child(1)>span:nth-child(5)>span>span:nth-child(2)>a {
    color: #ffffff;
    text-decoration: underline;
  }

  .truncate>div>span:nth-child(2)>a {
    color: #ffffff;
    text-decoration: underline;
  }

  .shop-body {
    min-height: calc(100vh - 315px);
  }

  .insta-image-favicon-container {
    position: relative;
    width: -webkit-fit-content;
  }

  .p-toggleable-content-dropdown .p-toggleable-content {
    margin-left: 10%;
  }

  .dialog-min-height .p-dialog-content {
    min-height: 300px;
  }
  
  .p-dialog-content {
    overflow-y: auto;
  }
  
  .sub-tables {
    background-color: lightgray;
  }
  
  .wide_modal {
    min-width: 1200px;
  }

  legend {
    font-size: 24px !important;
    border-bottom: 0px;
    margin: 0px;
  }

  /* cross browser fix for the payment data input */

  .table-product-col-options-description,
  .table-product-col-description {
    width: 230px;
    max-width: 230px;
  }

  .table-product-col-description {
    @include media-breakpoint-down(md) {
      max-width: unset;
      width: unset;
      display: flex !important;
    }
  }

  .table-product-options-description {
    @include media-breakpoint-down(sm) {
      max-width: 240px;
    }
  }

  .p-sortable-column-badge {
    text-align: center !important;
  }

  // MENU
  .mobile-menu {
    display: none;
  }
  .menu-top {
    display: none;
  }
  .tiered-menu{
    display: block;
  }

  #profile-header .profile-image {
    height: 140px;
    width: 140px;
  }

  #profile-header {
    background-image: linear-gradient(to right, $pink , #fa73b4);
  }

  #profileActiveCardHeader {
    border-bottom: 1px solid #eaeaea;
  }

  #profileCardHeader .p-disabled, .p-component:disabled {
    opacity: 1;
  }

  #dashboardProduct .product-text {
    word-wrap: normal;
    font-size: 11px;
    font-weight: bold;
  }

  #dashboardProduct .card-body {
    width: 100%;
  }

  #dashboardProduct .card {
    border-left: 3px solid $pink;
  }

  #dashboardProduct .card-body-inactive {
    border-top: 3px solid gray;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    width: 100%;
  }

  #dashboardProduct .card-body-template {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .mobile-menu {
      display: flex !important;
      height: 440px;
      overflow-y: inherit;
    }
    .menu-top {
      display: block !important;
    }
    .tiered-menu{
      display: none;
    }
    .layout-mask {
      z-index: 998;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      opacity: .7;
      background-color: #383838;
      -webkit-animation: layoutMaskFade .3s;
      animation: layoutMaskFade .3s;
    }
  }

  /* Date range picker */
  .date-picker-container {
    border: 1px solid #a6a6a6;
  }

  /* Width sizing */
  .col-width-4 {
    width: 4%;
  }

  .col-width-5 {
    width: 5%;
  }

  .col-width-5 {
    width: 5%;
  }

  .col-width-7 {
    width: 7%;
  }

  .col-width-10 {
    width: 10%;
  }

  .col-width-15 {
    width: 15%;
  }

  .col-width-20 {
    width: 20%;
  }
  
  .col-width-40 {
    width: 40%;
  }

  .loginForm {
    padding: 3rem;
  }

  /* Responsive datatable */
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td > .datatablecolumn-screen-s {
    display: none;
  }
  
  @media (max-width: 575px) {
    .responsiveBaseDataTable .p-datatable .p-filter-column {
      display: none;
    }
    .responsiveBaseDataTable .p-datatable .d-col-sm-none {
      display: none;
    }
    .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td > .datatablecolumn-screen-s {
      display: table;
    }
  }

  @media (min-width: 575px) and (max-width: 1199px) {
    .licenseDataTable .p-datatable .p-filter-column:nth-child(2),
    .licenseDataTable .p-datatable .p-filter-column:nth-child(5),
    .licenseDataTable .p-datatable .p-filter-column:nth-child(9),
    .activationDataTable .p-datatable .p-filter-column:nth-child(4),
    .activationDataTable .p-datatable .p-filter-column:nth-child(6),
    .clientActivationDataTable .p-datatable .p-filter-column:nth-child(n + 5),
    .entitlementDataTable .p-datatable .p-filter-column:nth-child(n + 4),
    .appDataTable .p-datatable .p-filter-column:nth-child(n + 5),
    .logDataTable .p-datatable .p-filter-column:nth-child(n + 4) {
      display: none;
    }
    .responsiveBaseDataTable .p-datatable .d-col-md-none {
      display: none;
    }
    .responsiveBaseDataTable.expanded .licenseActivationDataTable .p-datatable table,
    .responsiveBaseDataTable.expanded .fileLinkedEntitlementsDataTable .p-datatable table
    {
      display: table;
      flex-flow: unset;
    }
  }

  /*image previewer classes*/
  .removeImageBtn {
    display: inline;
    position: absolute;
    top: -10px;
    border-radius: 10em;
    padding: 3px 6px 3px;
    text-decoration: none;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(53, 49, 49, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }
  
  .removeImageBtn:hover {
    background: #e54e4e;
    color: white;
    border: 3px solid #fff;
    text-decoration: none;
  }
  
  .removeImageBtn:active {
    background: #e54e4e;
    color: white;
    border: 3px solid #fff;
    text-decoration: none;
  }
  
  /* Radio button custom style */
  /* Customize the label (the container) */
  .radio_button_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio_button_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .radio_button_container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio_button_container .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a green background */
  .radio_button_container input:checked ~ .checkmark {
    background-color: green;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio_button_container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio_button_container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio_button_container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  /* end cutom radio button */

  /*password reset page */
  .forgotPasswordPage {
    margin: auto;
    min-height: 100vh;
  }
  
  .forgotPasswordContainer {
    padding: 3em;
  }

  .main {
    background: #f3f3f3;
  }
  
  .general-template {
    background-color: lightgray;
  }


  @import '_prime-components';
  @import '_button';
}

html .licenseDataTable .badge {
  font-size: 12px;
}

.display-linebreak {
  white-space: pre-line;
}

/* selected product styling on client. */
.productCard {
  cursor: pointer;
}
.selectedProduct {
  box-shadow: 2px 2px 2px #aeacac;
  border: 1px solid $pink;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: var(--primary-control-color) !important;
}