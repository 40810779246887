.custom-border-bottom-style {
  border-bottom: 1px solid #c8c8c8;
}

.custom-border-top-style {
  border-top: 1px solid #c8c8c8;
}

.global-products-container > div > div {
  border: 0 solid transparent !important;
  padding-top: 0 !important;
}

.global-products-container .p-panel-header {
  border-radius: 15px;
}

.individual-product-container > div > div {
  border: 0 solid transparent !important;
}

.individual-product-container .p-panel-header {
  border-radius: 15px;
}