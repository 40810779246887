/*!
 * _button.scss (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file _button.scss
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

 .btn-primary:hover:not([disabled]) {
  background-color: var(--primary-control-hover-color) !important;
  border: 1px solid var(--primary-control-hover-color) !important;
}

.btn-primary {
  background-color: $primaryColor;
  border: 1px solid $primaryColor;

  &.btn-outline-primary {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    color: #fff;
    border-radius: 25px;

    .pi {
      color: $white;
    }

    &:disabled {
      background-color: $primaryColor;
      color: $white;
      border: 1px solid white;
      border-radius: 25px;

      .pi {
        color: $white;
      }
    }

    &:hover:not([disabled]) {
      background-color: transparent;
      color: $primaryColor;
      border: 1px solid $primaryColor;
      border-radius: 25px;

      .pi {
        color: $primaryColor;
      }
    }

    &:active:not([disabled]) {
      background-color: #d10065;
      border-color: $primaryColor;
    }
  }
}


.btn-destroy{
  background-color: transparent;
  border: none;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &.btn-outline-secondary {
    background-color: $primaryColor;
    color: #ffffff !important;
    border: 1px solid $primaryColor;
    border-radius: 25px;
  
    &:hover {
      background-color: transparent;
      color: $primaryColor !important;
      border: 1px solid $primaryColor;
    }
  
    &:focus {
      box-shadow: none;
    }
  
    &:active {
      background-color: #d10065;
    }
  }
}

.theme-mode-button > .p-button-label{
  color: #cbd3da;
}

.theme-mode-button > .p-button-icon-left{
  color: #cbd3da;
}