.custom-page-header-container > h2 {
  text-align: start;
}

.p-datatable-thead tr th {
  white-space: nowrap;
}

.p-datatable table {
  table-layout: auto;
}

.userData-button {
  width: 300px;
}

.saveAddress-col {
  margin-top: 2.7rem;
}

.light-section .saveAddress-col .saveAddress-button {
  height: 37.52px;
}

@media screen and (max-width: 575px) {
  .p-datatable table {
    table-layout: fixed !important;
  }

  .edit-delete-column {
    position: absolute;
    margin-top: -5px;
    top: 0;
    right: -15px;
  }
}

@media (max-width: 677px) {
  .userData-button {
    width: 100%;
    margin: 0 !important;
  }

  .userData-button:first-child {
    margin-bottom: 5px !important;
  }
}

@media (max-width: 1268px) {
  .update-braintree-customer-address-button {
    margin-top: 10px !important;
  }
}

/* Overwrite specific CSS property from sidebar.css of AWC */
.navbar-collapse {
  margin-top: 0 !important;
}

.dialog-container .product-image {
  max-width: 225px;
  height: auto;
}

/* Overwrite default p-card class */
.p-card {
  border-radius: var(--primary-border-radius);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #333;
  background-color: #f3f3f3;
}

.p-card .p-card-body {
  padding: 20px;
}

.p-card .p-card-header {
  padding: 20px 20px 0 20px;
}

/* Overwrite custom p-card for elements that must keep the original card style. Examples: login pages and first shop page */
.keep-original-card-style {
  border-radius: var(--primary-border-radius);
  background: #ffffff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 0;
  border: none;
}

/* Overwrite custom card title style. Example: first shop page */
.keep-original-card-title-style > .p-card-body > .p-card-title {
  border-image: none;
  border-bottom: none;
}

/* Border and background color for radio button elements */
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-highlight .p-checkbox .p-checkbox-box {
  background: var(--primary-control-color);
  border-color: var(--primary-control-color);
  box-shadow: none;
}

/* Hover for radio button and checkbox elements */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--primary-control-hover-color) !important;
  border-color: var(--primary-control-hover-color) !important;
  color: #fff;
}

/* Selection color */
body ::selection {
  background-color: var(--primary-control-color);
  color: var(--selection-color-highlighted);
}

/* Overwrite Button CSS of some dropdrown components. e.g Settings page */
.p-autocomplete > .p-autocomplete-dropdown {
  border: transparent;
  background: transparent;
  border-left: transparent;
  color: grey;
  margin-left: -35px;
}

.p-autocomplete > .p-autocomplete-dropdown:hover {
  border: transparent;
  background: transparent;
  color: grey;
}

/* Added border radius bottom radius to p-fieldset component */
.p-fieldset .p-fieldset-content {
  border-radius: var(--primary-border-bottom-radius);
}

.p-fieldset .p-fieldset-content {
  padding: 16px;
}

/* Added border radius to inputs and others elements */
.p-inputtext,
.p-multiselect,
.p-dropdown,
.p-autocomplete-dd .p-autocomplete-input {
  border-radius: var(--primary-border-radius);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-dropdown,
.p-dropdown-panel,
.p-dropdown-panel .p-dropdown-header,
.p-autocomplete-panel,
.p-multiselect-panel,
.p-multiselect-panel .p-multiselect-header {
  border-radius: var(--secondary-border-radius);
}

/* Apply padding to the selectors components */
.p-autocomplete-items-wrapper,
.p-autocomplete-panel .p-autocomplete-items,
.p-multiselect-items-wrapper {
  padding: 0.75rem 0;
}

/* Start of add border radius to dialog component */
.custom-dialog-container .p-dialog-footer {
  border-radius: var(--primary-border-bottom-radius);
}
/* End of add border radius to dialog component */

.p-dialog {
  box-shadow: none;
  border: none;
}

.p-dialog .p-dialog-header,
.p-fieldset .p-fieldset-legend {
  border: none;
  border-radius: var(--primary-border-top-radius);
}

/* Custom class to apply our secondary border radius value. */
.secondary-border-radius {
  border-radius: var(--secondary-border-radius) !important;
}

/** Add border radius to dropdrown options container */
.p-panel .p-panel-header {
  border-radius: var(--primary-border-top-radius);
}

/* Remove transition effect in selectors component. */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  transition: 0s;
}

.error-text {
  color: #a80000;
}

.popover {
  z-index: 9999;
}
#shopcontent .languageSelector {
  padding-bottom: 10px;
}
#shopcontent .languageSelector .p-dropdown .p-inputtext {
  font-family: var(--font-family);
  font-size: 16px;
}
#shopcontent .languageSelector .p-dropdown {
  width: 71.41px;
}
#shopcontent .languageSelector .p-icon {
  width: 16px;
  height: 16px;
}
#shopcontent .customLanguageSelectorDropdown span img {
  margin-bottom: 0px !important;
  margin-left: 4px;
}
.productTable-header-margin {
  margin-right: 6.5rem;
}