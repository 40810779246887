@media screen and (max-width: 575px) {
  .custom-address-container-expasion-row {
    padding: 0 !important;
  }
}

.custom-total-price-width-column {
  width: 150px;
}

.custom-userEmail-width-column {
  width: 300px;
}